<template>
  <div class="container mt-3">
    <nav
      aria-label="breadcrumb"
      class="py-2"
    >
      <ol class="breadcrumb">
        <li class="breadcrumb-item" style="cursor: pointer">
          <router-link to="/" class="text-black"
            ><i class="fas fa-home"></i
          ></router-link>
        </li>
        <li class="breadcrumb-item" style="cursor: pointer">
          <router-link to="/rujukan" class="text-black">Galeri</router-link>
        </li>
        <li class="breadcrumb-item" v-if="type" style="cursor: pointer">
          {{ helper.snakeToTitleCase(type) }}
        </li>
        <li
          class="breadcrumb-item active"
          v-if="fileName"
          style="cursor: pointer"
        >
          {{ helper.snakeToTitleCase(fileName) }}
        </li>
      </ol>
    </nav>

    <div class="modal" id="modal-set-bookmark" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              <i class="far fa-bookmark me-2" />Add Bookmark
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="hideModal"
            ></button>
          </div>
          <div class="modal-body">
            <!-- <div class="d-flex justify-content-center w-100"> -->
            <div class="p-2">
              <label>Title</label>
              <input
                v-model="models.title"
                class="form-control w-100"
                type="text"
              />
            </div>
            <!-- <div class="p-2">
                <label>Page</label>
                <input
                  v-model="models.pageNumbers"
                  class="form-control"
                  type="number"
                />
              </div> -->
            <!-- </div> -->
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              @click="hideModal"
            >
              Close
            </button>
            <button type="button" class="btn btn-primary" @click="saveChange">
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" id="modal-bookmark-list" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              <i class="fas fa-list-ul me-2"></i>List of bookmark
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="hideModal"
            ></button>
          </div>
          <div class="modal-body">
            <table class="table">
              <thead>
                <tr>
                  <th>Title</th>
                  <!-- <th>Page</th> -->
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  style="cursor: pointer"
                  v-for="(book, idx) in bookmarks"
                  :key="idx"
                >
                  <td @click="gotoPage(book)">{{ book.title }}</td>
                  <!-- <td @click="gotoPage(book)">{{ book.pageNumbers }}</td> -->
                  <td style="width: 50px">
                    <div class="text-danger" @click="handleRemove(idx)">
                      <i class="fas fa-times" />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              @click="hideModal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="book_container">
      <div id="book"></div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import API from "../../utils/API";
import { mapGetters, mapActions } from "vuex";
import Helper from "./../../utils/helper";

export default {
  name: "viewPDFFile",
  data() {
    return {
      isProcessing: false,
      galeri: null,
      type: "",
      fileName: "",
      helper: Helper,
      bookmarks: [],
      url: "",
      totalPage: 0,
      bookmark_id: null,
      otherBookMarks: [],
      models: {
        id: null,
        title: "",
        pageNumbers: null,
      },
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  methods: {
    handleRemove(idx) {
      const selectedTitle = this.bookmarks[idx].title;
      const dataBook = this.bookmarks
        .filter((x, i) => i != idx)
        .map((x) => {
          return {
            gallery_file: x.id,
            title: x.title,
            page_no: x.pageNumbers,
          };
        });
      let payload = {
        User_bookmarks: [...this.otherBookMarks, ...dataBook],
      };

      API.put(`users/${this.currentUser.id}`, payload)
        .then(async (res) => {
          await this.getUserBookmark(
            this.$route.params && this.$route.params.id
              ? this.$route.params.id
              : null,
            this.currentUser.id
          );
          Swal.fire(
            "Removed!",
            `Bookmark "${selectedTitle}" has been removed.`,
            "success"
          );
          this.models = {
            title: "",
            pageNumbers: null,
          };
        })
        .catch((err) => {
          this.models = {
            title: "",
            pageNumbers: null,
          };
        });

      this.hideModal();
    },
    saveChange() {
      var book = $.wowBook("#book");
      var currentPage = book && book.currentPage ? book.currentPage : 0;

      const dataBook = this.bookmarks.map((x) => {
        return {
          gallery_file: x.id,
          title: x.title,
          page_no: x.pageNumbers,
        };
      });

      const combineData = [...dataBook, ...this.otherBookMarks];
      let payload = {
        User_bookmarks: [
          ...combineData,
          {
            gallery_file: this.bookmark_id,
            title: this.models.title,
            page_no: currentPage,
          },
        ],
      };
      API.put(`users/${this.currentUser.id}`, payload)
        .then(async (res) => {
          await this.getUserBookmark(
            this.$route.params && this.$route.params.id
              ? this.$route.params.id
              : null,
            this.currentUser.id
          );
          Swal.fire("Saved!", `Title: ${this.models.title}`, "success");
          this.models = {
            title: "",
            pageNumbers: null,
          };
        })
        .catch((err) => {
          this.models = {
            title: "",
            pageNumbers: null,
          };
        });

      this.hideModal();
    },
    gotoPage(_bookmark) {
      var book = $.wowBook("#book");
      book.showPage(parseInt(_bookmark.pageNumbers));
    },
    getUserBookmark(book_id, user_id) {
      this.bookmarks = [];
      const qs = require("qs");
      const query = qs.stringify(
        {
          populate: {
            User_bookmarks: {
              populate: ["gallery_file"],
            },
          },
        },
        {
          encodeValuesOnly: true,
        }
      );
      API.get(`users/${user_id}?${query}`).then(async (res) => {
        let newItem = [];
        let otherBookMarks = [];
        res.data.User_bookmarks.forEach((x) => {
          if (x.gallery_file.id == book_id) {
            newItem.push({
              id: x.gallery_file.id,
              title: x.title,
              pageNumbers: x.page_no,
            });
          } else {
            otherBookMarks.push({
              gallery_file: x.gallery_file.id,
              title: x.title,
              page_no: x.page_no,
            });
          }
          this.otherBookMarks = otherBookMarks;
          this.bookmarks = newItem;
        });
      });
    },
    getGaleri(id) {
      this.isProcessing = true;
      API.get(
        `gallery-files/${id}?[populate][0]=background_image&[populate]=file`
      )
        .then(async (res) => {
          this.isProcessing = false;
          let obj = res.data.data;
          if (
            obj &&
            obj.attributes &&
            obj.attributes.file &&
            obj.attributes.file.data &&
            obj.attributes.file.data.attributes
          ) {
            let urls = obj.attributes.file.data.attributes.url.includes(
              "https://"
            )
              ? obj.attributes.file.data.attributes.url
              : "https://" + obj.attributes.file.data.attributes.url;
            this.galeri = urls;
            if (urls) {
              this.generatePDF(urls);
            }
          }
          this.fileName = obj.attributes.title ? obj.attributes.title : "Title";
          this.type = obj.attributes.type ? obj.attributes.type : "Type";
        })
        .catch((err) => {
          this.isProcessing = false;
        });
    },
    generatePDF(urls) {
      $("#book").wowBook({
        height: 700,
        width: 1000,
        maxHeight: 600,
        centeredWhenClosed: true,
        hardcovers: true,
        pageNumbers: false,
        toolbar:
          this.currentUser == null
            ? "home,thumbnails,zoomin,zoomout,lastLeft,left,right,lastRight,find,fullscreen"
            : "home,thumbnails,bookmark,lob,zoomin,zoomout,lastLeft,left,right,lastRight,download,find,fullscreen",
        thumbnailsPosition: "left",
        responsiveHandleWidth: 50,
        container: window,
        containerPadding: "20px",
        toolbarContainerPosition: "top",
        pdf: urls,
        pdfFind: true,
        closable: true,
        homeURL: "/rujukan?section=flipbook",
      });
    },
    hideModal() {
      $("#modal-set-bookmark").hide();
      $("#modal-bookmark-list").hide();
    },
  },
  async created() {
    $(document).on("click", ".wowbook-control-bookmark", function () {
      $("#modal-set-bookmark").show();
    });
    $(document).on("click", ".wowbook-control-lob", function () {
      $("#modal-bookmark-list").show();
    });

    this.bookmark_id =
      this.$route.params && this.$route.params.id
        ? this.$route.params.id
        : null;

    if (this.currentUser != null) {
      await this.getUserBookmark(
        this.$route.params && this.$route.params.id
          ? this.$route.params.id
          : null,
        this.currentUser.id
      );
    }

    await this.getGaleri(
      this.$route.params && this.$route.params.id ? this.$route.params.id : null
    );
  },
};
</script>
